var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function() {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "c7" }, [
      _c("p", { staticClass: "c14", attrs: { id: "h.gjdgxs" } }, [
        _c("span", { staticClass: "c8" }, [
          _vm._v("Política de Privacidade e Confidencialidade")
        ])
      ]),
      _c("p", { staticClass: "c9" }, [
        _c("span", { staticClass: "c12 c15" }, [
          _vm._v("Atualizado 24 de Janeiro de 2019")
        ])
      ]),
      _c("p", { staticClass: "c11" }, [
        _c(
          "span",
          {
            staticStyle: {
              overflow: "hidden",
              display: "inline-block",
              margin: "0.00px 0.00px",
              border: "0.00px solid #000000",
              transform: "rotate(0.00rad) translateZ(0px)",
              "-webkit-transform": "rotate(0.00rad) translateZ(0px)"
            }
          },
          [
            _c("img", {
              staticStyle: {
                width: "220.00px",
                "margin-left": "-29.00px",
                "margin-top": "-0.00px",
                transform: "rotate(0.00rad) translateZ(0px)",
                "-webkit-transform": "rotate(0.00rad) translateZ(0px)"
              },
              attrs: {
                alt: "",
                src: require("../assets/policies/image1.jpg"),
                title: ""
              }
            })
          ]
        )
      ]),
      _c("p", { staticClass: "c9" }, [
        _c("span", { staticClass: "c4" }, [_vm._v("Escrito(a) por ")]),
        _c("span", { staticClass: "c13" }, [_vm._v("Fabrício Hertz")]),
        _c("span", { staticClass: "c4 c12" }, [_vm._v(" "), _c("br")])
      ]),
      _c("p", { staticClass: "c16" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Este termo de uso apresenta as condições da Política de Privacidade e Confidencialidade da Informação na utilização dos serviços do site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa.ag")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ", do qual HORUS AERONAVES LTDA - EPP / CNPJ: 19.664.563/0001-02, com sede na cidade de Florianópolis – SC na Rod. José Carlos Daux, 600 - Bairro João Paulo – CEP 88030-000, é titular."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag (“SITE”), pela natureza dos serviços prestados recolhe e, em alguns casos, revela informações sobre os USUÁRIOS e VISITANTES de sua plataforma. Esta Política de Privacidade e Confidencialidade (“Política”) descreve a informação que o SITE e/ou Serviço coletam sobre seus usuários e visitantes e as regras a serem adotadas, a fim de garantir aos mesmos total transparência no tratamento das informações disponibilizadas."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("Com esta Política, o site ")
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag quer demonstrar que a privacidade da informação dos usuários/visitantes é muito importante e, por isso, toma precauções e cautelas para resguardar toda a informação, utilizando os mecanismos de segurança em informática mais completos, modernos e eficazes."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Este documento é parte integrante do Termo e Condições de Uso (TCDU) do site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag, a aceitação do TCDU no momento do cadastro o usuário implica na automática aceitação desta Política."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Ao cadastrar-se e/ou navegar pelo SITE e/ou Serviço, o USUÁRIO e/ou o VISITANTE consente tacitamente na utilização de sua informação pessoal, de acordo com as regras a seguir:"
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [_vm._v("DA INFORMAÇÃO EXIGIDA")])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("A primeira providência para desfrutar dos serviços do site ")
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            '.ag é registrar-se e informar seus dados pessoais ("DADOS PESSOAIS") completos e exatos. Poderão ser solicitados, recebidos e armazenados os seguintes dados: nome de utilizador para operar no SITE e/ou Sistema, nome, número de documento ou identificação válida e informação de contato (como número de telefone fixo, endereço de e-mail, residência ou domicílio, etc.). O site '
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ".ag poderá confirmar os dados pessoais informados e fica desde já autorizada a consultar entidades públicas, companhias especializadas ou bancos de dados, etc. A informação que o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(".ag obtiver será tratada de forma confidencial.")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "A qualquer momento o usuário (“USUÁRIO”) registrado no SITE e/ou Serviço poderá solicitar a extinção ou remoção de seu registro, bem como a eliminação de seus dados da base de dados do site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            '.ag. Para mais informações, leia "Direitos de acesso, remoção e retificação da informação pessoal" abaixo.'
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag coleta e armazena automaticamente informações sobre a atividade dos USUÁRIOS e VISITANTES de sua plataforma. Tal informação pode incluir a URL de onde eles provêm (seja ela do próprio SITE ou não), a que URL acederam em seguida (seja ela do próprio SITE ou não), o navegador que utilizam no momento do acesso, seus IPs de acesso, as páginas visitadas, as buscas realizadas, as imagens inseridas, mensagens, informações de voos, informações de aeronaves, informações de geolocalização, dentre outras informações poderão ser armazenadas e retidas."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag poderá coletar e armazenar os dados contidos nas correspondências que receber de: usuários/visitantes ou terceiros sejam elas via plataforma, email, fax ou qualquer outra. "
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DO USO DA INFORMAÇÃO PELO SITE www.mappa.ag")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Para fornecer um serviço de qualidade e para que seus USUÁRIOS possam realizar operações de forma ágil e segura, o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag requer informações de caráter pessoal, incluindo endereço de e-mail. A coleta dessas informações nos permite oferecer aos usuários serviços e funcionalidades que possam ser melhor adequados às suas necessidades e personalizar nossos serviços."
          ),
          _c("br")
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v(
            "A INFORMAÇÃO PESSOAL QUE SOLICITAMOS TEM AS SEGUINTES FINALIDADES:"
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v(" a) Ajudar o site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(".ag a entrar em contato com o USUÁRIO quando for necessário.")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            " b) Desenvolver estudos internos sobre os interesses, comportamentos e demografia dos USUÁRIOS para compreender melhor suas necessidades e interesses e oferecer melhores serviços ou prover-lhes informação relacionada."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            " c) Melhorar nossas iniciativas comerciais e promocionais e analisar as páginas visitadas, buscas realizadas pelos USUÁRIOS, para melhorar nossa oferta de conteúdos e produtos, personalizar conteúdos, sua apresentação e serviços. Enviar informação ou mensagens por e-mail sobre novos serviços, mostrar publicidades ou promoções, banners, de interesse para nossos usuários, notícias sobre o SITE e/ou Serviço, além da informação expressamente autorizada na seção de preferências. O USUÁRIO poderá solicitar que o excluam das listas para o envio de informação promocional ou publicitária."
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DA CONFIDENCIALIDADE DA INFORMAÇÃO")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("Uma vez registrado em seu web site, o site ")
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag não venderá, alugará ou compartilhará os DADOS PESSOAIS, exceto nas formas aqui estabelecidas."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("a) O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag se compromete a fazer tudo o que estiver ao alcance no sentido de proteger a privacidade da informação."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "b) Em virtude de ordens judiciais, ou de disposições legais, o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ".ag pode ser compelido a revelar informação às autoridades ou terceiras partes, ou em casos de terceiros possam interceptar a informação ou transmitir dados, nestes casos o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(".ag não responderá pela informação que seja revelada.")
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [_vm._v("DA SENHA PESSOAL")])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Para acesso aos serviços reservados unicamente aos USUÁRIOS devidamente registrados, estes irão dispor de uma senha pessoal. Com esta senha poderão acessar todos os serviços da plataforma. Esta senha, que é escolhida pelo próprio USUÁRIO e deve ser mantida sob absoluta confidencialidade e, em nenhum caso, deverá ser revelada ou compartilhada com outras pessoas."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "O USUÁRIO será responsável por todos os atos que sejam realizados com o uso de seu nome de usuário e senha pessoal, o que incluem pagamentos de tarifas por serviços ou responsabilização por eventuais prejuízos que possam vir a sofrer. Se por qualquer razão um USUÁRIO desconfiar que alguém pode conhecer sua senha pessoal, deverá modificá-la acessando seu perfil no SITE. "
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [_vm._v("DOS COOKIES")])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "O USUÁRIO e o VISITANTE da plataforma manifestam conhecer e aceitar que pode ser utilizado um sistema de monitoramento mediante utilização de cookies. Os cookies são pequenos arquivos que se instalam no disco rígido, com uma duração limitada de tempo que ajudam a personalizar os serviços. Também serão ofertadas algumas funcionalidades que só estarão disponíveis pelo emprego dos cookies. Os cookies são utilizados com o fim de conhecer os interesses, o comportamento e a demografia de quem visita ou é USUÁRIO da plataforma, e dessa forma, compreender melhor suas necessidades e interesses e dar-lhes um melhor serviço ou prover-lhes informação relacionada."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Também será usada a informação obtida por intermédio dos cookies para analisar as páginas navegadas pelo visitante ou USUÁRIO, as buscas realizadas, melhorar as iniciativas comerciais e promocionais, mostrar publicidade ou promoções, banners de interesse, notícias sobre o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag, aperfeiçoar a oferta de conteúdos e bens, personalizar tais conteúdos, apresentação e serviços. Também poderá haver utilização de cookies para promover e fazer cumprir as regras e segurança do SITE e/ou Serviço."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag poderá adicionar cookies nos e-mails que envia para medir a efetividade das promoções."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Utilizam-se adicionalmente os cookies para que o USUÁRIO não tenha que introduzir sua senha tão frequentemente durante uma seção de navegação, para contabilizar e corroborar os registros de atividade do USUÁRIO."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Os cookies são utilizados sempre visando o benefício do USUÁRIO que o recebe, e não será usado com outros fins alheios à operação do site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [_vm._v(".ag.")])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "A instalação, permanência e existência dos cookies no computador do USUÁRIO depende de sua exclusiva vontade e pode ser eliminado de seu computador quando o USUÁRIO assim o desejar."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Fica esclarecido que esta política cobre a utilização de cookies pelo site "
          )
        ]),
        _c("span", { staticClass: "c2 c6" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ".ag e não a utilização de cookies por parte de anunciantes. O site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(".ag não tem controle do uso de cookies por terceiros.")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Nossa política de cookies está de acordo com as mais recentes diretrizes da legislação existente sobre o tema."
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [_vm._v("DOS WEB BEACONS")])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Um Web beacon é uma imagem eletrônica, também chamada semgle-pixel (1 x 1) ou pixel transparente, que é colocada em código de uma página da web. Um Web beacon tem finalidades similares aos Cookies acima mencionados. "
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Adicionalmente um Web beacon é utilizado para medir padrões de tráfego dos usuários de uma página a outra com objetivo de maximizar o fluxo de tráfego através da web. O USUÁRIO e o VISITANTE da plataforma manifesta conhecer e aceitar que o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag poderá utilizar um sistema de monitoramento mediante a utilização de Web beacons. "
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DA ORDEM DE AUTORIDADES COMPETENTES - REQUERIMENTOS LEGAIS")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag coopera com as autoridades competentes e terceiros para garantir o cumprimento das leis, especialmente, em matéria de proteção de direitos de propriedade industrial e intelectual, prevenção de fraudes, entre outros."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag poderá revelar os DADOS PESSOAIS de seus USUÁRIOS para atender a requerimentos de autoridades judiciais ou governamentais competentes, para fins de investigações conduzidas por estas, mesmo que não exista uma ordem judicial. "
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag pode revelar qualquer DADO PESSOAL sobre seus USUÁRIOS com a finalidade de cumprir a lei aplicável e cooperar com as autoridades competentes para qualquer investigação de ilícito, infração de direitos de propriedade industrial ou intelectual, ou outra atividade que seja ilegal ou que possa expor a empresa ou seus USUÁRIOS, diretores, funcionários e prepostos a qualquer responsabilidade legal. "
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ".ag pode, ainda, revelar o nome completo do USUÁRIO, nome de USUÁRIO, domicílio, município, distrito, cep, país, número de telefone, endereço de e-mail, etc. aos participantes do Programa de Proteção de Propriedade Intelectual do SITE de forma que, possa ser investigada a fraude, infração de direitos de propriedade industrial ou intelectual, pirataria, ou qualquer outra atividade ilegal. Este direito será exercido pelo site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag a fim de cooperar com o cumprimento e execução da lei, independentemente de existir ou não uma ordem judicial ou administrativa para tanto."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("Além disso, o site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag se reserva o direito de revelar os DADOS PESSOAIS de seus USUÁRIOS a outros USUÁRIOS, entidades ou terceiros, quando haja motivos suficientes para considerar que a atividade de um USUÁRIO seja suspeita de tentar ou de cometer um delito ou prejuízo a outras pessoas. Este direito será utilizado pela plataforma quando considere apropriado ou necessário para manter a integridade e a segurança da Comunidade e de seus USUÁRIOS, bem como para fazer cumprir os Termos e Condições Gerais (TCDU) e demais políticas da Plataforma e para cooperar com a execução e cumprimento da lei."
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DA SEGURANÇA E ARMAZENAMENTO DA INFORMAÇÃO")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            '.ag é obrigada a observar todas as normas aplicáveis em matéria de medidas de segurança dos DADOS PESSOAIS e para tanto emprega os padrões mais avançados da indústria em matéria de proteção da confidencialidade das informações pessoais, a incluir, entre outras medidas, firewalls e Secure Socket Layers ("SSL"). O site '
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag considera os DADOS PESSOAIS de seus USUÁRIOS como um bem precioso que deve ser protegido de qualquer perda, acesso ou divulgação não autorizada."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ".ag emprega, portanto, diversas técnicas de segurança para proteger tais DADOS PESSOAIS, não autorizando sua utilização por USUÁRIOS dentro ou fora da empresa. Ainda assim, é necessário considerar que a segurança perfeita não existe na internet. Portanto, o site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag não será responsável por interceptações ilegais ou violação de seus sistemas ou bases de dados por parte de pessoas não autorizadas, tampouco se responsabilizará pela indevida utilização da informação obtida por esses meios."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Todos os DADOS PESSOAIS dos USUÁRIOS são armazenados em um arquivo automatizado de DADOS PESSOAIS, locado no Estados Unidos da América ou eventualmente em outra região/país se necessário. O USUÁRIO, ao registrar-se no SITE e/ou Serviço, confirma que está informado da localização deste arquivo e autoriza esta transferência internacional de seus dados, se for o caso."
          ),
          _c("br")
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DAS MUDANÇAS NAS PREFERÊNCIAS DE E-MAILS")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("Sem prejuízo do site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            ".ag querer manter seus USUÁRIOS atualizados a todo momento sobre promoções, novidades, mudanças, etc. os USUÁRIOS podem selecionar os e-mails e informações promocionais que gostariam de receber do site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag. Se o USUÁRIO não quiser receber e-mails, pode alterar suas preferências de e-mails para que sejam consideradas nas futuras comunicações."
          )
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DA INFORMAÇÃO NA SEÇÃO DE PERGUNTAS E RESPOSTAS OU FÓRUNS")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [_vm._v("O site ")]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            '.ag permite realizar perguntas e respostas em seu ambiente e além disso mantém fóruns públicos ("fóruns") que são fáceis de usar e navegar. Nestas seções os USUÁRIOS podem realizar comentários, dar opiniões, iniciar discussões e temas de seu interesse em um ambiente amigável, sempre sujeitos às regras aplicáveis nesta POLÍTICA.'
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v(
            "Os DADOS PESSOAIS inseridos na seção de perguntas e respostas ou nos fóruns podem ser lidos, coletados ou utilizados por qualquer pessoa. O site "
          )
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag não será responsável pelo uso dessa informação publicada pelo USUÁRIO nestes campos do SITE e/ou Serviço. "
          ),
          _c("br")
        ])
      ]),
      _c("p", { staticClass: "c5" }, [
        _c("span", { staticClass: "c10" }, [
          _vm._v("DOS DIREITOS DE CANCELAMENTO E RETIFICAÇÃO DE DADOS PESSOAIS")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            " O USUÁRIO, ao cadastrar-se, manifesta conhecer e pode exercer seus direitos de acessar, cancelar e atualizar seus DADOS PESSOAIS. O USUÁRIO garante e responde pela veracidade, exatidão, vigência e autenticidade dos dados pessoais, e se compromete a mantê-los devidamente atualizados."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Uma vez cadastrado no SITE e/ou Serviço, o USUÁRIO poderá revisar e alterar a informação que houver fornecido durante o processo de cadastro, incluindo:"
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "a) nome de utilizador e endereço de e-mail, neste caso, sem prejuízo das mudanças que efetue, a Plataforma conservará os DADOS PESSOAIS originais por motivos de segurança e controle de fraudes;"
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v("b) informação do registro como: número de telefone, etc.")
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [_vm._v("c) senha pessoal.")])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c2" }, [
          _vm._v("Em determinados casos, serão mantidos nos arquivos o site ")
        ]),
        _c("span", { staticClass: "c6 c2" }, [
          _c(
            "a",
            { staticClass: "c3", attrs: { href: "https://www.mappa.ag" } },
            [_vm._v("www.mappa")]
          )
        ]),
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            ".ag os dados pessoais que nos tenham sido solicitados para remoção com o objetivo de utilização na solução de litígios ou reclamações, detecção de problemas ou incidentes e dar cumprimento ao disposto no TCDU. "
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "Em qualquer caso, os DADOS PESSOAIS do USUÁRIO não serão imediatamente retirados dos arquivos por razões legais e técnicas, a incluir os sistemas de suporte e segurança."
          )
        ])
      ]),
      _c("p", { staticClass: "c1" }, [
        _c("span", { staticClass: "c0" }, [
          _vm._v(
            "O USUÁRIO deve atualizar seus DADOS PESSOAIS regularmente, com a alteração dos campos, a fim de que outros USUÁRIOS possam contatá-los. Para fazer qualquer modificação na informação fornecida quando do cadastramento, o USUÁRIO deve acessar seu perfil pessoal na Plataforma."
          )
        ])
      ]),
      _c("p", { staticClass: "c18" }, [_c("span", { staticClass: "c12 c17" })])
    ])
  }
]
render._withStripped = true

export { render, staticRenderFns }