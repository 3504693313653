<template>
  <div class="c7">
    <p class="c14" id="h.gjdgxs"><span class="c8">Pol&iacute;tica de Privacidade e Confidencialidade</span></p>
    <p class="c9"><span class="c12 c15">Atualizado 24 de Janeiro de 2019</span></p>
    <p class="c11"><span
      style="overflow: hidden; display: inline-block; margin: 0.00px 0.00px; border: 0.00px solid #000000; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"><img
      alt="" src="../assets/policies/image1.jpg"
      style="width: 220.00px; margin-left: -29.00px; margin-top: -0.00px; transform: rotate(0.00rad) translateZ(0px); -webkit-transform: rotate(0.00rad) translateZ(0px);"
      title=""></span></p>
    <p class="c9"><span class="c4">Escrito(a) por&nbsp;</span><span class="c13">Fabr&iacute;cio Hertz</span><span
      class="c4 c12">&nbsp;<br></span></p>
    <p class="c16"><span class="c2">Este termo de uso apresenta as condi&ccedil;&otilde;es da Pol&iacute;tica de Privacidade e Confidencialidade da Informa&ccedil;&atilde;o na utiliza&ccedil;&atilde;o dos servi&ccedil;os do site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa.ag</a></span><span
      class="c0">, do qual HORUS AERONAVES LTDA - EPP / CNPJ: 19.664.563/0001-02, com sede na cidade de Florian&oacute;polis &ndash; SC na Rod. Jos&eacute; Carlos Daux, 600 - Bairro Jo&atilde;o Paulo &ndash; CEP 88030-000, &eacute; titular.</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag&nbsp;(&ldquo;SITE&rdquo;), pela natureza dos servi&ccedil;os prestados recolhe e, em alguns casos, revela informa&ccedil;&otilde;es sobre os USU&Aacute;RIOS e VISITANTES de sua plataforma. Esta Pol&iacute;tica de Privacidade e Confidencialidade (&ldquo;Pol&iacute;tica&rdquo;) descreve a informa&ccedil;&atilde;o que o SITE e/ou Servi&ccedil;o coletam sobre seus usu&aacute;rios e visitantes e as regras a serem adotadas, a fim de garantir aos mesmos total transpar&ecirc;ncia no tratamento das informa&ccedil;&otilde;es disponibilizadas.</span>
    </p>
    <p class="c1"><span class="c2">Com esta Pol&iacute;tica, o site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                                                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag quer demonstrar que a privacidade da informa&ccedil;&atilde;o dos usu&aacute;rios/visitantes &eacute; muito importante e, por isso, toma precau&ccedil;&otilde;es e cautelas para resguardar toda a informa&ccedil;&atilde;o, utilizando os mecanismos de seguran&ccedil;a em inform&aacute;tica mais completos, modernos e eficazes.</span>
    </p>
    <p class="c1"><span class="c2">Este documento &eacute; parte integrante do Termo e Condi&ccedil;&otilde;es de Uso (TCDU) do site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag, a aceita&ccedil;&atilde;o do TCDU no momento do cadastro o usu&aacute;rio implica na autom&aacute;tica aceita&ccedil;&atilde;o desta Pol&iacute;tica.</span>
    </p>
    <p class="c1"><span class="c0">Ao cadastrar-se e/ou navegar pelo SITE e/ou Servi&ccedil;o, o USU&Aacute;RIO e/ou o VISITANTE consente tacitamente na utiliza&ccedil;&atilde;o de sua informa&ccedil;&atilde;o pessoal, de acordo com as regras a seguir:</span>
    </p>
    <p class="c5"><span class="c10">DA INFORMA&Ccedil;&Atilde;O EXIGIDA</span></p>
    <p class="c1"><span
      class="c2">A primeira provid&ecirc;ncia para desfrutar dos servi&ccedil;os do site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag &eacute; registrar-se e informar seus dados pessoais (&quot;DADOS PESSOAIS&quot;) completos e exatos. Poder&atilde;o ser solicitados, recebidos e armazenados os seguintes dados: nome de utilizador para operar no SITE e/ou Sistema, nome, n&uacute;mero de documento ou identifica&ccedil;&atilde;o v&aacute;lida e informa&ccedil;&atilde;o de contato (como n&uacute;mero de telefone fixo, endere&ccedil;o de e-mail, resid&ecirc;ncia ou domic&iacute;lio, etc.). O site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag poder&aacute; confirmar os dados pessoais informados e fica desde j&aacute; autorizada a consultar entidades p&uacute;blicas, companhias especializadas ou bancos de dados, etc. A informa&ccedil;&atilde;o que o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag obtiver ser&aacute; tratada de forma confidencial.</span></p>
    <p class="c1"><span class="c2">A qualquer momento o usu&aacute;rio (&ldquo;USU&Aacute;RIO&rdquo;) registrado no SITE e/ou Servi&ccedil;o poder&aacute; solicitar a extin&ccedil;&atilde;o ou remo&ccedil;&atilde;o de seu registro, bem como a elimina&ccedil;&atilde;o de seus dados da base de dados do site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag. Para mais informa&ccedil;&otilde;es, leia &quot;Direitos de acesso, remo&ccedil;&atilde;o e retifica&ccedil;&atilde;o da informa&ccedil;&atilde;o pessoal&quot; abaixo.</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag coleta e armazena automaticamente informa&ccedil;&otilde;es sobre a atividade dos USU&Aacute;RIOS e VISITANTES de sua plataforma. Tal informa&ccedil;&atilde;o pode incluir a URL de onde eles prov&ecirc;m (seja ela do pr&oacute;prio SITE ou n&atilde;o), a que URL acederam em seguida (seja ela do pr&oacute;prio SITE ou n&atilde;o), o navegador que utilizam no momento do acesso, seus IPs de acesso, as p&aacute;ginas visitadas, as buscas realizadas, as imagens inseridas, mensagens, informa&ccedil;&otilde;es de voos, informa&ccedil;&otilde;es de aeronaves, informa&ccedil;&otilde;es de geolocaliza&ccedil;&atilde;o, dentre outras informa&ccedil;&otilde;es poder&atilde;o ser armazenadas e retidas.</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag poder&aacute; coletar e armazenar os dados contidos nas correspond&ecirc;ncias que receber de: usu&aacute;rios/visitantes ou terceiros sejam elas via plataforma, email, fax ou qualquer outra.&nbsp;</span>
    </p>
    <p class="c5"><span class="c10">DO USO DA INFORMA&Ccedil;&Atilde;O PELO SITE www.mappa.ag</span></p>
    <p class="c1"><span class="c2">Para fornecer um servi&ccedil;o de qualidade e para que seus USU&Aacute;RIOS possam realizar opera&ccedil;&otilde;es de forma &aacute;gil e segura, o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag requer informa&ccedil;&otilde;es de car&aacute;ter pessoal, incluindo endere&ccedil;o de e-mail. A coleta dessas informa&ccedil;&otilde;es nos permite oferecer aos usu&aacute;rios servi&ccedil;os e funcionalidades que possam ser melhor adequados &agrave;s suas necessidades e personalizar nossos servi&ccedil;os.<br>&nbsp;</span>
    </p>
    <p class="c5"><span
      class="c10">A INFORMA&Ccedil;&Atilde;O PESSOAL QUE SOLICITAMOS TEM AS SEGUINTES FINALIDADES:</span></p>
    <p class="c1"><span class="c2">&nbsp;a) Ajudar o site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag a entrar em contato com o USU&Aacute;RIO quando for necess&aacute;rio.</span></p>
    <p class="c1"><span class="c0">&nbsp;b) Desenvolver estudos internos sobre os interesses, comportamentos e demografia dos USU&Aacute;RIOS para compreender melhor suas necessidades e interesses e oferecer melhores servi&ccedil;os ou prover-lhes informa&ccedil;&atilde;o relacionada.</span>
    </p>
    <p class="c1"><span class="c0">&nbsp;c) Melhorar nossas iniciativas comerciais e promocionais e analisar as p&aacute;ginas visitadas, buscas realizadas pelos USU&Aacute;RIOS, para melhorar nossa oferta de conte&uacute;dos e produtos, personalizar conte&uacute;dos, sua apresenta&ccedil;&atilde;o e servi&ccedil;os. Enviar informa&ccedil;&atilde;o ou mensagens por e-mail sobre novos servi&ccedil;os, mostrar publicidades ou promo&ccedil;&otilde;es, banners, de interesse para nossos usu&aacute;rios, not&iacute;cias sobre o SITE e/ou Servi&ccedil;o, al&eacute;m da informa&ccedil;&atilde;o expressamente autorizada na se&ccedil;&atilde;o de prefer&ecirc;ncias. O USU&Aacute;RIO poder&aacute; solicitar que o excluam das listas para o envio de informa&ccedil;&atilde;o promocional ou publicit&aacute;ria.</span>
    </p>
    <p class="c5"><span class="c10">DA CONFIDENCIALIDADE DA INFORMA&Ccedil;&Atilde;O</span></p>
    <p class="c1"><span class="c2">Uma vez registrado em seu web site, o site&nbsp;</span><span class="c6 c2"><a
      class="c3"
      href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag n&atilde;o vender&aacute;, alugar&aacute; ou compartilhar&aacute; os DADOS PESSOAIS, exceto nas formas aqui estabelecidas.</span>
    </p>
    <p class="c1"><span class="c2">a) O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                                href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag se compromete a fazer tudo o que estiver ao alcance no sentido de proteger a privacidade da informa&ccedil;&atilde;o.</span>
    </p>
    <p class="c1"><span
      class="c2">b) Em virtude de ordens judiciais, ou de disposi&ccedil;&otilde;es legais, o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag pode ser compelido a revelar informa&ccedil;&atilde;o &agrave;s autoridades ou terceiras partes, ou em casos de terceiros possam interceptar a informa&ccedil;&atilde;o ou transmitir dados, nestes casos o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag n&atilde;o responder&aacute; pela informa&ccedil;&atilde;o que seja revelada.</span></p>
    <p class="c5"><span class="c10">DA SENHA PESSOAL</span></p>
    <p class="c1"><span class="c0">Para acesso aos servi&ccedil;os reservados unicamente aos USU&Aacute;RIOS devidamente registrados, estes ir&atilde;o dispor de uma senha pessoal. Com esta senha poder&atilde;o acessar todos os servi&ccedil;os da plataforma. Esta senha, que &eacute; escolhida pelo pr&oacute;prio USU&Aacute;RIO e deve ser mantida sob absoluta confidencialidade e, em nenhum caso, dever&aacute; ser revelada ou compartilhada com outras pessoas.</span>
    </p>
    <p class="c1"><span class="c0">O USU&Aacute;RIO ser&aacute; respons&aacute;vel por todos os atos que sejam realizados com o uso de seu nome de usu&aacute;rio e senha pessoal, o que incluem pagamentos de tarifas por servi&ccedil;os ou responsabiliza&ccedil;&atilde;o por eventuais preju&iacute;zos que possam vir a sofrer. Se por qualquer raz&atilde;o um USU&Aacute;RIO desconfiar que algu&eacute;m pode conhecer sua senha pessoal, dever&aacute; modific&aacute;-la acessando seu perfil no SITE.&nbsp;</span>
    </p>
    <p class="c5"><span class="c10">DOS COOKIES</span></p>
    <p class="c1"><span class="c0">O USU&Aacute;RIO e o VISITANTE da plataforma manifestam conhecer e aceitar que pode ser utilizado um sistema de monitoramento mediante utiliza&ccedil;&atilde;o de cookies. Os cookies s&atilde;o pequenos arquivos que se instalam no disco r&iacute;gido, com uma dura&ccedil;&atilde;o limitada de tempo que ajudam a personalizar os servi&ccedil;os. Tamb&eacute;m ser&atilde;o ofertadas algumas funcionalidades que s&oacute; estar&atilde;o dispon&iacute;veis pelo emprego dos cookies. Os cookies s&atilde;o utilizados com o fim de conhecer os interesses, o comportamento e a demografia de quem visita ou &eacute; USU&Aacute;RIO da plataforma, e dessa forma, compreender melhor suas necessidades e interesses e dar-lhes um melhor servi&ccedil;o ou prover-lhes informa&ccedil;&atilde;o relacionada.</span>
    </p>
    <p class="c1"><span class="c2">Tamb&eacute;m ser&aacute; usada a informa&ccedil;&atilde;o obtida por interm&eacute;dio dos cookies para analisar as p&aacute;ginas navegadas pelo visitante ou USU&Aacute;RIO, as buscas realizadas, melhorar as iniciativas comerciais e promocionais, mostrar publicidade ou promo&ccedil;&otilde;es, banners de interesse, not&iacute;cias sobre o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag, aperfei&ccedil;oar a oferta de conte&uacute;dos e bens, personalizar tais conte&uacute;dos, apresenta&ccedil;&atilde;o e servi&ccedil;os. Tamb&eacute;m poder&aacute; haver utiliza&ccedil;&atilde;o de cookies para promover e fazer cumprir as regras e seguran&ccedil;a do SITE e/ou Servi&ccedil;o.</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag poder&aacute; adicionar cookies nos e-mails que envia para medir a efetividade das promo&ccedil;&otilde;es.</span>
    </p>
    <p class="c1"><span class="c0">Utilizam-se adicionalmente os cookies para que o USU&Aacute;RIO n&atilde;o tenha que introduzir sua senha t&atilde;o frequentemente durante uma se&ccedil;&atilde;o de navega&ccedil;&atilde;o, para contabilizar e corroborar os registros de atividade do USU&Aacute;RIO.</span>
    </p>
    <p class="c1"><span class="c2">Os cookies s&atilde;o utilizados sempre visando o benef&iacute;cio do USU&Aacute;RIO que o recebe, e n&atilde;o ser&aacute; usado com outros fins alheios &agrave; opera&ccedil;&atilde;o do site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag.</span></p>
    <p class="c1"><span class="c0">A instala&ccedil;&atilde;o, perman&ecirc;ncia e exist&ecirc;ncia dos cookies no computador do USU&Aacute;RIO depende de sua exclusiva vontade e pode ser eliminado de seu computador quando o USU&Aacute;RIO assim o desejar.</span>
    </p>
    <p class="c1"><span class="c2">Fica esclarecido que esta pol&iacute;tica cobre a utiliza&ccedil;&atilde;o de cookies pelo site&nbsp;</span><span
      class="c2 c6"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag e n&atilde;o a utiliza&ccedil;&atilde;o de cookies por parte de anunciantes. O site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag n&atilde;o tem controle do uso de cookies por terceiros.</span></p>
    <p class="c1"><span class="c0">Nossa pol&iacute;tica de cookies est&aacute; de acordo com as mais recentes diretrizes da legisla&ccedil;&atilde;o existente sobre o tema.</span>
    </p>
    <p class="c5"><span class="c10">DOS WEB BEACONS</span></p>
    <p class="c1"><span class="c0">Um Web beacon &eacute; uma imagem eletr&ocirc;nica, tamb&eacute;m chamada semgle-pixel (1 x 1) ou pixel transparente, que &eacute; colocada em c&oacute;digo de uma p&aacute;gina da web. Um Web beacon tem finalidades similares aos Cookies acima mencionados.&nbsp;</span>
    </p>
    <p class="c1"><span class="c2">Adicionalmente um Web beacon &eacute; utilizado para medir padr&otilde;es de tr&aacute;fego dos usu&aacute;rios de uma p&aacute;gina a outra com objetivo de maximizar o fluxo de tr&aacute;fego atrav&eacute;s da web. O USU&Aacute;RIO e o VISITANTE da plataforma manifesta conhecer e aceitar que o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag poder&aacute; utilizar um sistema de monitoramento mediante a utiliza&ccedil;&atilde;o de Web beacons.&nbsp;</span>
    </p>
    <p class="c5"><span class="c10">DA ORDEM DE AUTORIDADES COMPETENTES - REQUERIMENTOS LEGAIS</span></p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag coopera com as autoridades competentes e terceiros para garantir o cumprimento das leis, especialmente, em mat&eacute;ria de prote&ccedil;&atilde;o de direitos de propriedade industrial e intelectual, preven&ccedil;&atilde;o de fraudes, entre outros.</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag poder&aacute; revelar os DADOS PESSOAIS de seus USU&Aacute;RIOS para atender a requerimentos de autoridades judiciais ou governamentais competentes, para fins de investiga&ccedil;&otilde;es conduzidas por estas, mesmo que n&atilde;o exista uma ordem judicial.&nbsp;</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag pode revelar qualquer DADO PESSOAL sobre seus USU&Aacute;RIOS com a finalidade de cumprir a lei aplic&aacute;vel e cooperar com as autoridades competentes para qualquer investiga&ccedil;&atilde;o de il&iacute;cito, infra&ccedil;&atilde;o de direitos de propriedade industrial ou intelectual, ou outra atividade que seja ilegal ou que possa expor a empresa ou seus USU&Aacute;RIOS, diretores, funcion&aacute;rios e prepostos a qualquer responsabilidade legal.&nbsp;</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag pode, ainda, revelar o nome completo do USU&Aacute;RIO, nome de USU&Aacute;RIO, domic&iacute;lio, munic&iacute;pio, distrito, cep, pa&iacute;s, n&uacute;mero de telefone, endere&ccedil;o de e-mail, etc. aos participantes do Programa de Prote&ccedil;&atilde;o de Propriedade Intelectual do SITE de forma que, possa ser investigada a fraude, infra&ccedil;&atilde;o de direitos de propriedade industrial ou intelectual, pirataria, ou qualquer outra atividade ilegal. Este direito ser&aacute; exercido pelo site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag a fim de cooperar com o cumprimento e execu&ccedil;&atilde;o da lei, independentemente de existir ou n&atilde;o uma ordem judicial ou administrativa para tanto.</span>
    </p>
    <p class="c1"><span class="c2">Al&eacute;m disso, o site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                                                href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag se reserva o direito de revelar os DADOS PESSOAIS de seus USU&Aacute;RIOS a outros USU&Aacute;RIOS, entidades ou terceiros, quando haja motivos suficientes para considerar que a atividade de um USU&Aacute;RIO seja suspeita de tentar ou de cometer um delito ou preju&iacute;zo a outras pessoas. Este direito ser&aacute; utilizado pela plataforma quando considere apropriado ou necess&aacute;rio para manter a integridade e a seguran&ccedil;a da Comunidade e de seus USU&Aacute;RIOS, bem como para fazer cumprir os Termos e Condi&ccedil;&otilde;es Gerais (TCDU) e demais pol&iacute;ticas da Plataforma e para cooperar com a execu&ccedil;&atilde;o e cumprimento da lei.</span>
    </p>
    <p class="c5"><span class="c10">DA SEGURAN&Ccedil;A E ARMAZENAMENTO DA INFORMA&Ccedil;&Atilde;O</span></p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag &eacute; obrigada a observar todas as normas aplic&aacute;veis em mat&eacute;ria de medidas de seguran&ccedil;a dos DADOS PESSOAIS e para tanto emprega os padr&otilde;es mais avan&ccedil;ados da ind&uacute;stria em mat&eacute;ria de prote&ccedil;&atilde;o da confidencialidade das informa&ccedil;&otilde;es pessoais, a incluir, entre outras medidas, firewalls e Secure Socket Layers (&quot;SSL&quot;). O site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag considera os DADOS PESSOAIS de seus USU&Aacute;RIOS como um bem precioso que deve ser protegido de qualquer perda, acesso ou divulga&ccedil;&atilde;o n&atilde;o autorizada.</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag emprega, portanto, diversas t&eacute;cnicas de seguran&ccedil;a para proteger tais DADOS PESSOAIS, n&atilde;o autorizando sua utiliza&ccedil;&atilde;o por USU&Aacute;RIOS dentro ou fora da empresa. Ainda assim, &eacute; necess&aacute;rio considerar que a seguran&ccedil;a perfeita n&atilde;o existe na internet. Portanto, o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag n&atilde;o ser&aacute; respons&aacute;vel por intercepta&ccedil;&otilde;es ilegais ou viola&ccedil;&atilde;o de seus sistemas ou bases de dados por parte de pessoas n&atilde;o autorizadas, tampouco se responsabilizar&aacute; pela indevida utiliza&ccedil;&atilde;o da informa&ccedil;&atilde;o obtida por esses meios.</span>
    </p>
    <p class="c1"><span class="c0">Todos os DADOS PESSOAIS dos USU&Aacute;RIOS s&atilde;o armazenados em um arquivo automatizado de DADOS PESSOAIS, locado no Estados Unidos da Am&eacute;rica ou eventualmente em outra regi&atilde;o/pa&iacute;s se necess&aacute;rio. O USU&Aacute;RIO, ao registrar-se no SITE e/ou Servi&ccedil;o, confirma que est&aacute; informado da localiza&ccedil;&atilde;o deste arquivo e autoriza esta transfer&ecirc;ncia internacional de seus dados, se for o caso.<br>&nbsp;</span>
    </p>
    <p class="c5"><span class="c10">DAS MUDAN&Ccedil;AS NAS PREFER&Ecirc;NCIAS DE E-MAILS</span></p>
    <p class="c1"><span class="c2">Sem preju&iacute;zo do site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                                                  href="https://www.mappa.ag">www.mappa</a></span><span
      class="c2">.ag querer manter seus USU&Aacute;RIOS atualizados a todo momento sobre promo&ccedil;&otilde;es, novidades, mudan&ccedil;as, etc. os USU&Aacute;RIOS podem selecionar os e-mails e informa&ccedil;&otilde;es promocionais que gostariam de receber do site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag. Se o USU&Aacute;RIO n&atilde;o quiser receber e-mails, pode alterar suas prefer&ecirc;ncias de e-mails para que sejam consideradas nas futuras comunica&ccedil;&otilde;es.</span>
    </p>
    <p class="c5"><span class="c10">DA INFORMA&Ccedil;&Atilde;O NA SE&Ccedil;&Atilde;O DE PERGUNTAS E RESPOSTAS OU F&Oacute;RUNS</span>
    </p>
    <p class="c1"><span class="c2">O site&nbsp;</span><span class="c6 c2"><a class="c3"
                                                                             href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag permite realizar perguntas e respostas em seu ambiente e al&eacute;m disso mant&eacute;m f&oacute;runs p&uacute;blicos (&quot;f&oacute;runs&quot;) que s&atilde;o f&aacute;ceis de usar e navegar. Nestas se&ccedil;&otilde;es os USU&Aacute;RIOS podem realizar coment&aacute;rios, dar opini&otilde;es, iniciar discuss&otilde;es e temas de seu interesse em um ambiente amig&aacute;vel, sempre sujeitos &agrave;s regras aplic&aacute;veis nesta POL&Iacute;TICA.</span>
    </p>
    <p class="c1"><span class="c2">Os DADOS PESSOAIS inseridos na se&ccedil;&atilde;o de perguntas e respostas ou nos f&oacute;runs podem ser lidos, coletados ou utilizados por qualquer pessoa. O site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag n&atilde;o ser&aacute; respons&aacute;vel pelo uso dessa informa&ccedil;&atilde;o publicada pelo USU&Aacute;RIO nestes campos do SITE e/ou Servi&ccedil;o.&nbsp;<br>&nbsp;</span>
    </p>
    <p class="c5"><span class="c10">DOS DIREITOS DE CANCELAMENTO E RETIFICA&Ccedil;&Atilde;O DE DADOS PESSOAIS</span>
    </p>
    <p class="c1"><span class="c0">&nbsp;O USU&Aacute;RIO, ao cadastrar-se, manifesta conhecer e pode exercer seus direitos de acessar, cancelar e atualizar seus DADOS PESSOAIS. O USU&Aacute;RIO garante e responde pela veracidade, exatid&atilde;o, vig&ecirc;ncia e autenticidade dos dados pessoais, e se compromete a mant&ecirc;-los devidamente atualizados.</span>
    </p>
    <p class="c1"><span class="c0">Uma vez cadastrado no SITE e/ou Servi&ccedil;o, o USU&Aacute;RIO poder&aacute; revisar e alterar a informa&ccedil;&atilde;o que houver fornecido durante o processo de cadastro, incluindo:</span>
    </p>
    <p class="c1"><span class="c0">a) nome de utilizador e endere&ccedil;o de e-mail, neste caso, sem preju&iacute;zo das mudan&ccedil;as que efetue, a Plataforma conservar&aacute; os DADOS PESSOAIS originais por motivos de seguran&ccedil;a e controle de fraudes;</span>
    </p>
    <p class="c1"><span class="c0">b) informa&ccedil;&atilde;o do registro como: n&uacute;mero de telefone, etc.</span>
    </p>
    <p class="c1"><span class="c0">c) senha pessoal.</span></p>
    <p class="c1"><span class="c2">Em determinados casos, ser&atilde;o mantidos nos arquivos o site&nbsp;</span><span
      class="c6 c2"><a class="c3"
                       href="https://www.mappa.ag">www.mappa</a></span><span
      class="c0">.ag os dados pessoais que nos tenham sido solicitados para remo&ccedil;&atilde;o com o objetivo de utiliza&ccedil;&atilde;o na solu&ccedil;&atilde;o de lit&iacute;gios ou reclama&ccedil;&otilde;es, detec&ccedil;&atilde;o de problemas ou incidentes e dar cumprimento ao disposto no TCDU.&nbsp;</span>
    </p>
    <p class="c1"><span class="c0">Em qualquer caso, os DADOS PESSOAIS do USU&Aacute;RIO n&atilde;o ser&atilde;o imediatamente retirados dos arquivos por raz&otilde;es legais e t&eacute;cnicas, a incluir os sistemas de suporte e seguran&ccedil;a.</span>
    </p>
    <p class="c1"><span class="c0">O USU&Aacute;RIO deve atualizar seus DADOS PESSOAIS regularmente, com a altera&ccedil;&atilde;o dos campos, a fim de que outros USU&Aacute;RIOS possam contat&aacute;-los. Para fazer qualquer modifica&ccedil;&atilde;o na informa&ccedil;&atilde;o fornecida quando do cadastramento, o USU&Aacute;RIO deve acessar seu perfil pessoal na Plataforma.</span>
    </p>
    <p class="c18"><span class="c12 c17"></span></p>
  </div>
</template>

<script>
  export default {
    name: "PrivacyPolicy"
  }
</script>

<style scoped>
  ol {
    margin: 0;
    padding: 0
  }

  table td, table th {
    padding: 0
  }

  .c0 {
    color: #000000;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 12pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c10 {
    color: #3a3c4c;
    font-weight: 700;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 13.5pt;
    font-family: "Times New Roman";
    font-style: normal
  }

  .c18 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0791666666666666;
    orphans: 2;
    widows: 2;
    text-align: left;
    height: 11pt
  }

  .c8 {
    color: #3a3c4c;
    font-weight: 400;
    text-decoration: none;
    vertical-align: baseline;
    font-size: 25pt;
    font-style: normal
  }

  .c1 {
    padding-top: 0pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c14 {
    padding-top: 0pt;
    padding-bottom: 12.8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c11 {
    padding-top: 0pt;
    padding-bottom: 0pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c16 {
    padding-top: 14pt;
    padding-bottom: 14pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c5 {
    padding-top: 0pt;
    padding-bottom: 11.2pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c9 {
    padding-top: 0pt;
    padding-bottom: 8pt;
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .c15 {
    color: #8f919d;
    font-weight: 400;
    font-size: 15pt;
  }

  .c13 {
    font-size: 10pt;
    color: #4f5e6b;
    font-weight: 400
  }

  .c4 {
    font-size: 10pt;
    color: #8f919d;
    font-weight: 400
  }

  .c6 {
    -webkit-text-decoration-skip: none;
    color: #0000ff;
    text-decoration: underline;
    text-decoration-skip-ink: none
  }

  .c17 {
    color: #000000;
    font-weight: 400;
    font-size: 11pt;
  }

  .c2 {
    font-size: 12pt;
    font-family: "Times New Roman";
    font-weight: 400
  }

  .c7 {
    /*background-color: #ffffff;*/
    background-image: url("../assets/bg-neutral.jpg");
    background-size: cover;
    max-width: 100%;
    padding: 70.8pt 85pt 70.8pt 85pt
  }

  .c12 {
    text-decoration: none;
    vertical-align: baseline;
    font-style: normal
  }

  .c3 {
    color: inherit;
    text-decoration: inherit
  }

  .title {
    padding-top: 24pt;
    color: #000000;
    font-weight: 700;
    font-size: 36pt;
    padding-bottom: 6pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  .subtitle {
    padding-top: 18pt;
    color: #666666;
    font-size: 24pt;
    padding-bottom: 4pt;
    font-family: "Georgia";
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    font-style: italic;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  li {
    color: #000000;
    font-size: 11pt;
  }

  p {
    margin: 0;
    color: #000000;
    font-size: 11pt;
  }

  h1 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 24pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h2 {
    padding-top: 18pt;
    color: #000000;
    font-weight: 700;
    font-size: 18pt;
    padding-bottom: 4pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h3 {
    padding-top: 14pt;
    color: #000000;
    font-weight: 700;
    font-size: 14pt;
    padding-bottom: 4pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h4 {
    padding-top: 0pt;
    color: #000000;
    font-weight: 700;
    font-size: 12pt;
    padding-bottom: 8pt;
    font-family: "Times New Roman";
    line-height: 1.0;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h5 {
    padding-top: 11pt;
    color: #000000;
    font-weight: 700;
    font-size: 11pt;
    padding-bottom: 2pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }

  h6 {
    padding-top: 10pt;
    color: #000000;
    font-weight: 700;
    font-size: 10pt;
    padding-bottom: 2pt;
    line-height: 1.0791666666666666;
    page-break-after: avoid;
    orphans: 2;
    widows: 2;
    text-align: left
  }
</style>